<template>
  <div class="form">
    <div class="form-input flex-ac flex-jb">
      <div class="form-label flex-ac">旧密码</div>
      <input v-model="old_pwd" placeholder="请输入旧密码" type="password" />
    </div>
    <div class="form-input flex-ac flex-jb">
      <div class="form-label flex-ac">新密码</div>
      <input v-model="new_pwd" placeholder="请输入新密码" type="password" />
    </div>
    <div class="form-input flex-ac flex-jb">
      <div class="form-label flex-ac">确认新密码</div>
      <input
        v-model="check_pwd"
        placeholder="请输入确认新密码"
        type="password"
      />
    </div>
    <div
      :class="['submit-btn', 'btn', { active: allowSubmit }]"
      @click="submit"
    >
      提交
    </div>
  </div>
</template>
<script>
import { post } from '@/axios'

export default {
  name: 'setting-password',
  data() {
    return {
      old_pwd: '',
      new_pwd: '',
      check_pwd: ''
    }
  },
  methods: {
    submit() {
      const oldpassword = this.old_pwd
      const newpassword = this.new_pwd
      const okpassword = this.check_pwd
      if (!oldpassword) {
        this.$message.error('请输入旧密码', 2)
        return false
      }
      if (!newpassword) {
        this.$message.error('请输入新密码', 2)
        return false
      }
      if (!okpassword) {
        this.$message.error('请再次输入密码', 2)
        return false
      }
      if (newpassword !== okpassword) {
        this.$message.error('新密码不一致', 2)
        return false
      }
      const mapStr = JSON.stringify({
        usedPwd: oldpassword,
        newpassword
      })
      post('member_mobile_sureUsedPwd.do', this.Qs.stringify({ mapStr })).then(
        ({ code, data }) => {
          if (code === 'success') {
            const { flag } = JSON.parse(data)
            if (flag === 1) {
              this.$message.error('舊密碼不正確，請重新輸入', 2)
              this.old_pwd = ''
              return false
            } else {
              post(
                'member_mobile_updateUsedPwd.do',
                this.Qs.stringify({ mapStr })
              ).then(() => {
                this.$message.success('修改成功，重新登錄...', 2)
                setTimeout(() => {
                  this.$store.dispatch('logOut')
                }, 2000)
              })
            }
          } else {
            this.$store.dispatch('logOut')
          }
        }
      )
    }
  },
  computed: {
    allowSubmit() {
      return !!this.old_pwd && !!this.new_pwd && !!this.check_pwd
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.form {
  width: 800px;
}

.form-input {
  width: 100%;
  height: 50px;
  margin-bottom: 15px;
  border-bottom: 1px solid #777;
  color: white;

  input {
    background: transparent;
    box-shadow: none;
    border: none;
    width: 80%;
    height: 100%;
    color: white;
    font-size: 1rem;
  }
}

.submit-btn {
  width: 250px;
  height: 70px;
  // margin: 15px auto;
  position: absolute;
  right: 2rem;
  top: 250px;
}

@include md {
  .form {
    width: 350px;
    margin: 0 auto;
  }

  .form-input {
    .form-label {
      width: 40%;
      font-size: 18px;
    }

    input {
      font-size: 16px;
    }
  }

  .submit-btn {
    width: 180px;
    height: 50px;
    position: static;
    margin: 15px auto;
  }
}
</style>
